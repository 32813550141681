import React, { useEffect, useState } from "react";

import { axiosInstance, getCookie } from "../utills";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Cookies from "js-cookie";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler
);

function Dashboard() {
  const [userCountGraphData, setUserCountGraphData] = useState([]);
  const [doctorCountGraphData, setDoctorCountGraphData] = useState([]);
  const [bookedAppointmentCountGraphData, setBookedAppointmentCountGraphData] =
    useState([]);
  const [subscribedUserCountGraphData, setSubscribedUserCountGraphData] =
    useState([]);
  const [revenueGeneratedGraphData, setRevenueGeneratedGraphData] = useState(
    []
  );

  useEffect(() => {
    fetchUserCountGraphData();
    fetchDoctorCountGraphData();
    fetchSubscribeUserCountGraphData();
    fetchBookedAppointmentGraphData();
    fetchRevenuGeneratedGraphData();
  }, []);

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken !== null) {
      let config = {
        method: "get",
        url: "/dashboard/verify-token",
        headers: {
          Authorization: "Bearer " + accessToken.split("%20")[1],
        },
      };

      axiosInstance
        .request(config)
        .then((response) => {
          if (!response.data.success) {
            clearAllCookies();
          }
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      clearAllCookies();
    }
  }, []);

  const clearAllCookies = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    window.location.href = "/";
  };

  const fetchUserCountGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-user-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: {
            data: { monthWiseUserCounts },
          },
        } = response;

        setUserCountGraphData(monthWiseUserCounts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDoctorCountGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-doctors-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: {
            data: { monthWiseUserCounts },
          },
        } = response;

        setDoctorCountGraphData(monthWiseUserCounts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSubscribeUserCountGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-subscribe-user-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: {
            data: { monthWiseUserCounts },
          },
        } = response;

        setSubscribedUserCountGraphData(monthWiseUserCounts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchBookedAppointmentGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-appointment-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: {
            data: { monthWiseAppointmentCounts },
          },
        } = response;

        setBookedAppointmentCountGraphData(monthWiseAppointmentCounts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchRevenuGeneratedGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-revenue-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: { data },
        } = response;

        setRevenueGeneratedGraphData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="main">
      <div className="row scrollView">
        <div className="col-md-6" style={{ aspectRatio: 16 / 9, padding: 10 }}>
          <div className="dashboard-card">
            <Line
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "Revenue Generated",
                  },
                },
              }}
              data={{
                labels: revenueGeneratedGraphData?.map(({ month }) => month),
                datasets: [
                  {
                    fill: true,
                    label: "",
                    data: revenueGeneratedGraphData?.map(
                      ({ revenue }) => revenue
                    ),
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="col-md-6" style={{ aspectRatio: 16 / 9, padding: 10 }}>
          <div className="dashboard-card ">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "Subscribed Users",
                  },
                },
              }}
              data={{
                labels: subscribedUserCountGraphData?.map(({ month }) => month),
                datasets: [
                  {
                    fill: true,
                    label: "Subscribed Users",
                    data: subscribedUserCountGraphData?.map(
                      ({ subscribed }) => subscribed
                    ),
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                  {
                    fill: true,
                    label: "Unsubscribed Users",
                    data: subscribedUserCountGraphData?.map(
                      ({ unsubscribed }) => unsubscribed
                    ),
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="col-md-6" style={{ aspectRatio: 16 / 9, padding: 10 }}>
          <div className="dashboard-card ">
            <Line
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "No of users",
                  },
                },
              }}
              data={{
                labels: doctorCountGraphData?.map(({ month }) => month),
                datasets: [
                  {
                    fill: true,
                    label: "No. of doctors",
                    data: doctorCountGraphData?.map(({ count }) => count),
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                  },
                  {
                    fill: true,
                    label: "No. of users",

                    data: userCountGraphData?.map(({ count }) => count),
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                ],
              }}
            />
          </div>
        </div>

        <div className="col-md-6" style={{ aspectRatio: 16 / 9, padding: 10 }}>
          <div className="dashboard-card">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "Appointment Booked",
                  },
                },
              }}
              data={{
                labels: bookedAppointmentCountGraphData?.map(
                  ({ month }) => month
                ),
                datasets: [
                  {
                    fill: true,
                    label: "",
                    data: bookedAppointmentCountGraphData?.map(
                      ({ count }) => count
                    ),
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
