import React from "react";
import AboutImage from "../../assets/about-2.jpg";
import AboutBG from "../../assets/about-bg-subtle.jpg";
function About() {
  return (
    <div className="row reduce-70" style={{ minHeight: "100vh" }}>
      <div
        className="col-lg-6 remove-about"
        style={{
          backgroundImage: `url(${AboutImage})`,
          backgroundSize: "60%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="col-lg-6"
        style={{
          backgroundImage: `url(${AboutBG})`,
          backgroundSize: "70%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="d-flex flex-wrap align-items-end">
          <span className="p-t fifty black" style={{ lineHeight: 1.2 }}>
            About
          </span>
          &nbsp;&nbsp;
          <span
            className="p-t sixtyfour brightblue"
            style={{ lineHeight: 1.2 }}
          >
            AlignTrack
          </span>
        </div>
        <br />
        <p
          className="m-m twentyeight black reduce-text"
          style={{ lineHeight: 1 }}
        >
          At AlignTrack, we're revolutionizing the dental experience for both
          patients and professionals. Our mobile app connects the dental
          community, offering easy access to basic consultations, personalized
          care, and aligner prescriptions. But we don't stop there – we empower
          patients to monitor their aligner journey, fostering progress
          transparency and overall dental health.
        </p>
        <p
          className="m-m twentyeight black reduce-text"
          style={{ lineHeight: 1 }}
        >
          What sets us apart is our commitment to sustainability. We encourage
          patients to recycle aligners and plastics, promoting environmental
          awareness and responsibility in dental care. Join us in a journey
          towards a healthier, eco-friendly smile.
        </p>
      </div>
    </div>
  );
}

export default About;
