import React, { useEffect, useState } from "react";
import Table from "../Pages/component/Table";

import { axiosInstance, formatDate } from "../utills";

function Recycle() {
  const tableColumns = [
    "Name",
    "Contact",
    "FlatHouse",
    "Area",
    "Landmark",
    "State",
    "City",
    "Type of Waste",
    "Booking Date",
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Retrieve access token from cookies

    // API call configuration
    const config = {
      method: "get",
      url: "/dashboard/get-recycler-booking",
    };

    // API call
    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: { data },
        } = response;
        const extractedData = data.map((item) => {
          return {
            Name: item?.userId?.fName + " " + item?.userId?.lName,
            Contact: item?.userId?.contactDetails?.mobile?.primary?.number,
            FlatHouse: item?.addressLine1,
            Area: item?.addressLine2,
            Landmark: item?.nearBy,
            State: item?.state,
            City: item?.city,
            "Type of Waste": item?.typeOfWasteValue,
            "Booking Date": formatDate(item.createdAt),
          };
        });
        setTableData(extractedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="main">
      {/* <h3>Recycle</h3> */}
      <Table tableData={tableData} tableColumns={tableColumns} />
    </div>
  );
}

export default Recycle;
