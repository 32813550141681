import React, { useEffect, useState } from "react";
import Table from "../Pages/component/Table";

import { axiosInstance, formatDate, getCookie } from "../utills";

function Doctor() {
  const tableColumns = [
    "Name",
    "Contact",
    "Status",
    "Rating",
    "Address",
    "State",
    "City",
    "Degree",
    "Registration Date",
  ];
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    // Retrieve access token from cookies

    // API call configuration
    const config = {
      method: "get",
      url: "/dashboard/get-registered-doctors",
    };

    // API call
    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: { data },
        } = response;
        const extractedData = data.map((item) => {
          return {
            Name: item.fName,
            Contact: item.number,
            Status: item.isVerified ? "VERIFIED" : "UNVERIFIED",
            Rating: item.raiting,
            Address: item.currentLocation,
            State: item.currentLocation,
            City: item.currentLocation,
            Degree: item.qualification,
            "Registration Date": formatDate(item.createdAt),
          };
        });
        setTableData(extractedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="main">
      {/* <h3>Doctor</h3> */}
      <Table tableData={tableData} tableColumns={tableColumns} />
    </div>
  );
}

export default Doctor;
