import React, { useEffect, useState } from "react";
import Table from "../Pages/component/Table";

import { axiosInstance, formatDate } from "../utills";

function User() {
  const tableColumns = [
    "Name",
    "Contact",
    "FlatHouse",
    "Area",
    "Nearbylandmark",
    "State",
    "City",
    "DOB",
    "RegistrationDate",
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Retrieve access token from cookies

    // API call configuration
    const config = {
      method: "get",
      url: "/dashboard/get-registered-users",
    };

    // API call
    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: { data },
        } = response;
        const extractedData = data.map((item) => ({
          Name: item?.fName + " " + item?.lName,
          Contact: item?.contactDetails?.mobile?.primary.number,
          FlatHouse: item?.addressInfo[0]?.addressInfo?.address1,
          Area: item?.addressInfo[0]?.addressInfo?.address2,
          Landmark: item?.addressInfo[0]?.addressInfo?.nearby,
          State: item?.addressInfo[0]?.addressInfo?.state,
          City: item?.addressInfo[0]?.addressInfo?.city,
          DOB: item?.dob,
          RegistrationDate: formatDate(item.createdAt),
        }));
        setTableData(extractedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // Empty dependency array ensures this effect runs only once when component mounts

  return (
    <div className="main">
      {/* <h3>User</h3> */}
      <Table tableData={tableData} tableColumns={tableColumns} />
    </div>
  );
}

export default User;
