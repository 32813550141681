import React, { useState } from "react";
import Logo from "../assets/white-logo.svg";
import TextField from "@mui/material/TextField";

import Cookies from "js-cookie";
import { axiosInstance } from "../utills";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if either username or password is empty
    if (!username || !password) {
      alert("Please fill in both username and password fields.");
      return;
    }

    const data = new URLSearchParams();
    data.append("credentials", btoa(`${username}:${password}`));

    try {
      const response = await axiosInstance.post("/dashboard/login", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const {
        data: { accessToken, refreshToken },
        code,
        success,
        message,
      } = response.data;
      if (code === 200 && success) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1); // Expires in 7 days
        const expirationDateRefreshToken = new Date();
        expirationDateRefreshToken.setDate(expirationDate.getDate() + 7); // Expires in 7 days

        Cookies.set("accessToken", accessToken, { expires: 1 });
        Cookies.set("refreshToken", refreshToken, { expires: 7 });

        // Redirect to dashboard on successful login
        window.location.href = "/dashboard";
      } else {
        // Show alert for unexpected response status
        alert(message);
      }
    } catch (error) {
      console.error(error);
      // Show alert for login error
      alert("Login failed. Please check your credentials and try again.");
    }
  };

  return (
    <div className="container-fluid bg-blue flex-row-align-center">
      <div className="row">
        <div className="col-md-4 offset-md-4 login-card">
          <center>
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: "80px",
              }}
            />
          </center>
          <p className="bm-semi xl accent" style={{ marginTop: 57 }}>
            Admin Panel
          </p>
          <p style={{ marginTop: 15 }}>
            This page is exclusively intended for authorized personnel
            associated with our organization. If you are not a member of our
            organization's staff or management, please be advised that you have
            landed on the admin page, which is reserved for internal use only.
          </p>
          <p>
            For general information and access to our public-facing content,
            kindly visit the home page of our website. We appreciate your
            understanding and cooperation in maintaining the security and
            functionality of this platform.
          </p>

          <form onSubmit={handleSubmit}>
            <TextField
              id="filled-basic"
              label="Username"
              variant="filled"
              size="small"
              style={{
                marginTop: 30,
              }}
              className="login-text-field"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              id="filled-basic"
              type="password"
              label="Password"
              variant="filled"
              size="small"
              style={{
                marginTop: 15,
              }}
              className="login-text-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <center>
              <button type="submit" className="login-button">
                LOGIN
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
