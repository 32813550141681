import React from "react";

import HeaderImage from "../../assets/header.jpg";
import "./style.css";

function Header() {
  return (
    <div className="row padding170">
      <div
        data-aos="zoom-out"
        data-aos-duration="2000"
        className="col-lg-6 d-flex flex-column justify-content-center show-bg p-5 remove-p5"
      >
        <p className="p-t sixtyfour lightblue mobile" style={{ lineHeight: 1 }}>
          Revolutionize Your Dental Care with AlignTrack!
        </p>
        <br />
        <button className="m-b thirtytwo whiteColor getApp">GET THE APP</button>
      </div>
      <div
        data-aos="zoom-out"
        data-aos-duration="2000"
        className="col-lg-6 remove-bg"
      >
        <img src={HeaderImage} alt="header" style={{ width: "100%" }} />
      </div>
    </div>
  );
}

export default Header;
