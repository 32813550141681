import React, { useEffect, useState } from "react";
import Table from "../Pages/component/Table";

import { axiosInstance, formatDate } from "../utills";

function Appointment() {
  const tableColumns = [
    "Patient Name",
    "Doctor Name",
    "Appointment Date",
    "Rating",
  ];
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    // Retrieve access token from cookies

    // API call configuration
    const config = {
      method: "get",
      url: "/dashboard/get-appointments",
    };

    // API call
    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: { data },
        } = response;
        const extractedData = data.map((item) => {
          return {
            "Patient Name": item?.userId?.fName,
            "Doctor Name": item?.doctor?.fName,
            "Appointment Date": formatDate(item.date),
            Rating: item?.doctor?.raiting,
          };
        });
        setTableData(extractedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="main">
      {/* <h3>Appointment</h3> */}
      <Table tableData={tableData} tableColumns={tableColumns} />
    </div>
  );
}

export default Appointment;
