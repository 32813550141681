import axios from "axios";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName.trim() === name) {
      return cookieValue;
    }
  }
  return null;
}
function getMonthName(monthNumber) {
  const date = new Date(Date.UTC(2000, monthNumber - 1, 1)); // Subtract 1 because month numbers are zero-based in JavaScript Date objects
  const monthName = date.toLocaleString("default", { month: "long" });
  return monthName;
}

const axiosInstance = axios.create({
  baseURL: "https://api.aligntrack.in/api",
  headers: {
    Authorization: "Bearer " + getCookie("accessToken")?.split("%20")[1],
  },
});

export { formatDate, getCookie, getMonthName, axiosInstance };
