import React, { useState } from "react";
// import Contact from "../../assets/contact.svg";
import Phone from "../../assets/phone.svg";
import Gps from "../../assets/location.svg";
import Email from "../../assets/email.svg";
import TickFilled from "../../assets/tick-filled.svg";
import Circle from "../../assets/circle.svg";
import Instagram from "../../assets/footer-instagram.svg";
import X from "../../assets/footer-twitter.svg";
import Youtube from "../../assets/footer-youtube.svg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
function Contact() {
  const [Selected, updateSelected] = useState(1);
  return (
    <div className="row d-flex justify-content-center mt-5 mb-5">
      <div
        className="col-md-7 container-fluid rounded"
        style={{ borderWidth: 1, borderStyle: "solid" }}
      >
        <div className="row p-2">
          <div
            className="col-md-5 rounded pt-4"
            style={{ backgroundColor: "#0d96f6", paddingRight: 0 }}
          >
            <div>
              <p className="p-t thirtytwo" style={{ lineHeight: 1 }}>
                Contact Information
              </p>
              <p className="m-m greyText eighteen">
                Say something to start a live chat
              </p>
            </div>
            <div className="mt-5">
              <span className="m-m eighteen whiteColor">
                <img src={Phone} alt="ph" /> +91-9873735045
              </span>
              <br />
              <br />
              <span className="m-m eighteen whiteColor">
                <img src={Email} alt="em" />
                info.aligntrack@gmail.com
              </span>
              <br />
              <br />
              <span className="m-m eighteen whiteColor">
                <img src={Gps} alt="lo" />
                Sec-43, Faridabad, Haryana
              </span>
            </div>

            <br />
            <br />
            <div>
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex justify-content-around align-items-center"
                  style={{ width: "70%", maxWidth: 150 }}
                >
                  <a href="https://twitter.com/AlignTrack" target="_blank">
                    <img src={Instagram} alt="" style={{ width: 30 }} />
                  </a>
                  <a href="https://www.youtube.com/@aligntrack" target="_blank">
                    <img src={X} alt="" style={{ width: 30 }} />
                  </a>
                  <a
                    href="https://www.instagram.com/aligntrack_official"
                    target="_blank"
                  >
                    <img src={Youtube} alt="" style={{ width: 30 }} />
                  </a>
                </div>
                <img src={Circle} alt="" style={{ width: "30%" }} />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div>
              <TextField
                id="first"
                label="First Name"
                variant="standard"
                style={{ width: "48%" }}
              />
              &nbsp;
              <TextField
                id="last"
                label="Last Name"
                variant="standard"
                style={{ width: "48%" }}
              />
            </div>
            <div>
              <TextField
                id="email"
                label="Email"
                variant="standard"
                style={{ width: "48%" }}
              />
              &nbsp;
              <TextField
                id="mobile"
                label="Mobile"
                variant="standard"
                style={{ width: "48%" }}
              />
            </div>
            <p className="m-m eighteen black pt-4">Select Subject</p>
            <div className="d-flex align-items-center flex-wrap">
              <div className="d-flex align-items-center">
                <button
                  className="checkbox-container"
                  onClick={() => updateSelected(1)}
                >
                  {Selected === 1 ? (
                    <div className="checkbox-filled"></div>
                  ) : (
                    <></>
                  )}
                </button>
                &nbsp;General Enquiry&nbsp;&nbsp;
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="checkbox-container"
                  onClick={() => updateSelected(2)}
                >
                  {Selected === 2 ? (
                    <div className="checkbox-filled"></div>
                  ) : (
                    <></>
                  )}
                </button>
                &nbsp;About Platform&nbsp;&nbsp;
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="checkbox-container"
                  onClick={() => updateSelected(3)}
                >
                  {Selected === 3 ? (
                    <div className="checkbox-filled"></div>
                  ) : (
                    <></>
                  )}
                </button>
                &nbsp;Greviances&nbsp;&nbsp;
              </div>
            </div>

            <br />
            <TextField
              id="mobile"
              label="Message"
              variant="standard"
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <div className="d-flex justify-content-end">
              <Button
                variant="contained"
                style={{ backgroundColor: "#0d96f6" }}
              >
                Send Message
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
