import React from "react";

function Team() {
  const team = [
    {
      name: "Dr. Vineeta",
      description:
        "Associate Professor at Manav Rachna, is an epigenetics expert, researching gene secrets amidst climate change.",
      imgUrl: "./vineeta.jpeg",
    },
    {
      name: "Dr. Puneet",
      description:
        "MDS Orthodontics- AIIMS, President Elect of Indian Orthodontic Society, Cleft Orthodontist, and Dental Council nominee.",
      imgUrl: "./puneet.jpg",
    },
    {
      name: "Ms. Tanvi",
      description:
        "Passionate pre-final year engineering student merging biology and computer science, excelling academically.",
      imgUrl: "./tanvi.jpeg",
    },
    {
      name: "Ms. Swasti",
      description:
        "Co-Founders of AlignTrack, currently engaged in pursuing PhD in biotechnology from Manav Rachna.",
      imgUrl: "./swasti.jpeg",
    },
  ];
  return (
    <>
      <p
        className="p-t sixtyfour brightblue"
        style={{ textAlign: "center", lineHeight: 1 }}
      >
        TEAM TO TRUST
      </p>
      <br />
      <div className="row d-flex justify-content-around">
        {team.map((item, index) => (
          <div className="col-xl-2 col-lg-2 col-md-3" key={index}>
            <div
              className="greyBackground team-card"
              style={{
                backgroundImage: `url(${item.imgUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
            <p
              className="p-t twentyeight black mt-3"
              style={{ textAlign: "center" }}
            >
              {item.name}
            </p>
            <p
              className="mm eighteen black mt-3"
              style={{ textAlign: "center", lineHeight: 1.2 }}
            >
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}

export default Team;
