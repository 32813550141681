import React, { useEffect } from "react";
import Navigation from "./component/Navigation";
import Header from "./component/Header";
import Services from "./component/Services";
import AOS from "aos";
import "aos/dist/aos.css";
import { TabTitle } from "./DynamicTitle";
import { Element } from "react-scroll";
import Brush from "../assets/brush.png";
import Recycle from "../assets/recycle.png";
import Appointment from "../assets/appointment.png";
import About from "./component/About";
import Team from "./component/Team";
import Plan from "./component/Plan";
import Contact from "./component/Contact";
import Footer from "./component/Footer";

import "./component/style.css";

function Home() {
  TabTitle("Align Track");
  const data = [
    {
      title: "Personalised Dental Care",
      description:
        "Experience tailored dental treatments designed just for you, ensuring a healthy, confident smile.",
      image: Brush,
    },
    {
      title: "Online Consultation",
      description:
        "Convenient virtual consultations with our experts, providing expert advice and treatment plans from the comfort of your home.",
      image: Appointment,
    },
    {
      title: "Recycle Aligners",
      description:
        "Join our eco-friendly initiative by returning and recycling your aligners, contributing to a sustainable future for oral care.",
      image: Recycle,
    },
  ];

  useEffect(() => {
    AOS.init({
      once: false,
    });
    if (window) {
      window.addEventListener(
        "wheel",
        () => {
          AOS.refresh();
        },
        false
      );
    }
    AOS.refresh();
  }, []);
  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <Element name="home">
          <Header />
        </Element>
        <Element name="services">
          <div className="row d-flex justify-content-around bg-lightblue p-3">
            {data.map((item, index) => (
              <Services
                key={index}
                title={item.title}
                description={item.description}
                imgSrc={item.image}
              />
            ))}
          </div>
        </Element>

        <Element name="about">
          <About />
        </Element>
        <Team />
        <br />
        <br />
        <Element name="pricing">
          <Plan />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
        <Footer />
      </div>
    </>
  );
}

export default Home;
