import React, { useEffect, useState } from "react";
import Logo from "../assets/white-logo.svg";
import One from "../assets/1.svg";
import Two from "../assets/2.svg";
import Three from "../assets/3.svg";
import Four from "../assets/4.svg";
import Five from "../assets/5.svg";
import Six from "../assets/6.svg";
import Seven from "../assets/7.svg";
import Profile from "../assets/profile.svg";
import Recycle from "../assets/recycle.svg";

import { Outlet, Link } from "react-router-dom";

import { axiosInstance, getCookie } from "../utills";
import Cookies from "js-cookie";

// Function to clear all cookies

export default function Root() {
  const [active, updateActive] = useState("Analytics");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken !== null) {
      let config = {
        method: "get",
        url: "/dashboard/verify-token",
        headers: {
          Authorization: "Bearer " + accessToken.split("%20")[1],
        },
      };

      axiosInstance
        .request(config)
        .then((response) => {
          if (!response.data.success) {
            clearAllCookies();
          }
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      clearAllCookies();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clearAllCookies = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    window.location.href = "/";
  };
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", overflow: "hidden" }}
      >
        <div
          className="side-menu"
          style={{ display: screenWidth < 630 && "none" }}
        >
          <div className="top-holder">
            <center>
              <img src={Logo} alt="Logo" className="logo" />
            </center>
          </div>
          <div className="middle-holder">
            <span>
              <Link to="./" onClick={() => updateActive("Analytics")}>
                <img src={Two} alt="Dashboard" className="icn" />
                Dashboard
              </Link>
            </span>
            <span>
              <Link
                to="./users"
                onClick={() => updateActive("Registered Users")}
              >
                <img src={Six} alt="User" className="icn" />
                Registered Users
              </Link>
            </span>
            <span>
              <Link
                to="./paid-users"
                onClick={() => updateActive("Paid Users")}
              >
                <img src={Three} alt="User" className="icn" />
                Paid Users
              </Link>
            </span>
            <span>
              <Link
                to="./appointment"
                onClick={() => updateActive("Appointment")}
              >
                <img src={One} alt="Appointment" className="icn" />
                Appointment
              </Link>
            </span>
            <span>
              <Link
                to="./doctors"
                onClick={() => updateActive("Registered Doctors")}
              >
                <img src={Four} alt="Doctor" className="icn" />
                Registered Doctors
              </Link>
            </span>
            <span>
              <Link to="./blog" onClick={() => updateActive("Blog")}>
                <img src={Five} alt="Blog" className="icn" />
                Blogs
              </Link>
            </span>
            <span>
              <Link to="./recycle" onClick={() => updateActive("Recycle")}>
                <img src={Recycle} alt="Recycle" className="icn" />
                Recycle
              </Link>
            </span>
          </div>

          <div className="bottom-holder">
            <span
              onClick={() => {
                let config = {
                  method: "get",
                  url: "/dashboard/logout",
                };

                axiosInstance
                  .request(config)
                  .then((response) => {
                    clearAllCookies();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <a
                style={{
                  color: "white",
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginTop: "25px",
                  fontSize: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                  textDecoration: "none",
                  fontWeight: 800,
                  letterSpacing: "1px",
                }}
                href="/"
              >
                Logout <img src={Seven} alt="Logout" className="icn-2" />
              </a>
            </span>
          </div>
        </div>
        <div className="container-fluid main-section">
          <div
            className="row"
            style={{ backgroundColor: "#fafafa", minHeight: "100vh" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 top-nav-with-name">
                  <p className="page-heading">{active}</p>
                  <img src={Profile} alt="profile" />
                </div>
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
