import "./App.css";
import Login from "./Pages/Login";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home";
import ErrorPage from "./Pages/Error";
import Root from "./Pages/Root";
import Dashboard from "./Pages/Dashboard";
import User from "./Pages/User";
import Paid from "./Pages/Paid";
import Appointment from "./Pages/Appointment";
import Doctor from "./Pages/Doctor";
import Blog from "./Pages/Blog";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Recycle from "./Pages/Recycle";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/staff",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-conditions",
    element: <Terms />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "users",
        element: <User />,
      },
      {
        path: "paid-users",
        element: <Paid />,
      },
      {
        path: "appointment",
        element: <Appointment />,
      },
      {
        path: "doctors",
        element: <Doctor />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "recycle",
        element: <Recycle />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
